<template>
  <div>
    <el-table :data="list">
      <el-table-column label="Telegram ID" prop="tgid"></el-table-column>
      <el-table-column label="Дата" prop="date"></el-table-column>
      <el-table-column label="Имя" prop="name"></el-table-column>
      <el-table-column label="Логин" prop="login"></el-table-column>
      <el-table-column label="Телефон" prop="phone"></el-table-column>
      <el-table-column label="Вы живете в России?" prop="q1"></el-table-column>
      <el-table-column label="Часовой пояс" prop="q2"></el-table-column>
      <el-table-column label="Работа с документами" prop="q3"></el-table-column>
      <el-table-column label="Работа с людьми" prop="q4"></el-table-column>
      <el-table-column label="Опыт в продажах" prop="q5"></el-table-column>
      <el-table-column label="Про опыт" prop="q6"></el-table-column>
      <el-table-column label="Текущая занятость" prop="q7"></el-table-column>
      <el-table-column label="Работа, учета, возраст ребенка" prop="q8"></el-table-column>
      <el-table-column label="Занятость" prop="q9"></el-table-column>
      <el-table-column label="Время на задачи" prop="q10"></el-table-column>
      <el-table-column label="Сдельная оплата" prop="q11"></el-table-column>
      <el-table-column label="Денежные ожидания" prop="q12"></el-table-column>
      <el-table-column label="Процент от продаж" prop="q13"></el-table-column>
      <el-table-column label="Проходили ли обучающие курсы" prop="q14"></el-table-column>
      <el-table-column label="Расписание" prop="q15"></el-table-column>
      <el-table-column label="Готов на тестовое" prop="q16"></el-table-column>
    </el-table>
  </div>

</template>


<script>
import axios from 'axios'
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.get()
  },
  methods: {
    async get() {
      const {data} = await axios.post('ttbot')
      this.list = data;
    }
  }
}
</script>

<style>
.cell {
  white-space: nowrap!important;
  min-width: 300px
}
</style>